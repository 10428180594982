@use "../../../../styles/variables" as *;

//> Component : [ Header du projet ]
.project-header {
    @include section-styles;
    width: 100%;
    height: 50vh;
    position: relative;

    //=== [ Image du projet ]
    &__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 20px;
        filter: blur(0.2rem);

        //=== [ Titre du projet ]
        &__title {
            width: 100%;
            display: flex;
            justify-content: center;

            &__text {
                @include container-styles;
                width: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-transform: uppercase;
                font-size: clamp(1rem, 5vw, 3rem);
                -webkit-text-stroke: 1px $main-color;
                -webkit-text-fill-color: transparent;
                border: 1px solid rgba(255, 255, 255, 0.18);
                background-color: rgba(24, 24, 31, 0.256);
                box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
                backdrop-filter: blur(20px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
        }
    }
}
