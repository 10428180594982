@use '../../../../../styles/variables' as *;

.banner {
  height: 10%;
  background: $banner-bg;
  user-select: none;


  @include mobile-portrait {
    height: 10%;
  }

  @include mobile-landscape {
    height: 20%;
    display: none;
  }


  &__container {
    height: 100%;
    width: 100%;
    transform: skewY(-3deg);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    backdrop-filter: blur(20px);
    background-color: rgba(24, 24, 31, 0.8);

    &__text {
      height: 100%;
      display: flex;
      align-items: center;
      border-left: 3px dashed $main-color;
      padding-inline: 40px;

      &__icon {
        width: 32px;
        height: 32px;
        margin-inline: 10px;
      }

      &__item {
        font-size: clamp(1rem, 2vw, 1.5rem);
        font-weight: 400;
        text-transform: uppercase;
      }
    }
  }
}
