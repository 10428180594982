@use '../../../../styles/variables' as *;

.project-gallery {
  @include section-styles;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;


  .project-gallery__title {
    @include container-styles;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    -webkit-text-stroke: 1px $main-color;
    -webkit-text-fill-color: $main-color;
    font-weight: 800;
  }

  .project-gallery__content {
    display: flex;
    gap: 40px;
    width: 100%;
    height: 100%;

    @include mobile-portrait {
      flex-direction: column;
      gap: 20px;
    }

    .project-gallery__description {
      @include container-styles;
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 20px;


      @include mobile-portrait {
        width: 100%;
      }

      .project-gallery__description__title {
        font-size: 18px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        -webkit-text-stroke: 1px $main-color;
        -webkit-text-fill-color: $main-color;
        font-weight: 600;
      }

      .project-gallery__description__texte {
        font-size: 16px;
        text-align: justify;
        line-height: 1.75rem;
        font-weight: 200;
        overflow: scroll;

        @include mobile-portrait {
          height: 200px;
        }

        @include mobile-landscape {
          font-size: 0.8rem;
        }
      }
    }

    .project-gallery__carousel {
      width: 70%;
      height: 100%;

      @include mobile-portrait {
        width: 100%;
      }
    }
  }
}
