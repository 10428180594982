@use 'swiper/css/bundle';
@use '../../../../styles/variables' as *;


.swiper-screen-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  width: 100%;
  height: auto;

  @include mobile-portrait {
    flex-direction: column;
    gap: 20px;
  }

  .swiper-screen {
    width: 100%;
    height: 100%;
    max-height: 50vh;
    overflow: hidden;
    border-radius: 20px;

    @include mobile-portrait {
      max-height: 100%;
    }


    .swiper-slide-screen {
      border-radius: 20px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit:cover;
      border-radius: 20px;
    }
  }

  .swiper-elements-outside-screen {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 300px;

    @include mobile-portrait {
      flex-direction: row;
      justify-content: center;
      height: auto;
    }

    .swiper-button-prev-screen,
    .swiper-button-next-screen{
      border-radius: 50%;
      width: 40px;
      height: 40px;
      background-size: cover;
      border: 2px solid #ECE6D7;
      cursor: pointer;
      transform:rotate(90deg);

      @include mobile-portrait {
        transform:rotate(0deg);
        height: 30px;
      }

      &:hover {
        border: 2px dotted #ECE6D7;
      }
    }
  }
}

.swiper-button-prev-screen {
  background-image: url(../../../../assets/icons/arrow-left.svg);
  background-position: center;
}

.swiper-button-next-screen {
  background-image: url(../../../../assets/icons/arrow-right.svg);
    background-position: center;
}


.swiper-pagination-screen, .swiper-pagination-vertical-screen{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  padding-block: 20px;

  @include mobile-portrait {
    flex-direction: row;
    gap: 10px;
    padding-block: 10px;
  }
}
.swiper-pagination-bullet-screen {
    background-color: #ECE6D7;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 0.5;
}

.swiper-pagination-bullet-active-screen {
  background-color: #ECE6D7;
  width: 10px;
  height: 10px;
  opacity: 2;
}
