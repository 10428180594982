@use '../../styles/variables' as *;

.projects{
  display: flex;
  padding-top: 50px;
  padding-inline: 72px;
  min-height: $height-with-navbar;
  @include mobile-portrait {
    padding-inline: 20px;
  }

  .projects__cards {
    @include section-styles;
    width: 100%;
    height: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(250px, 3fr));
  }
}

.card__projects {
  height: 300px;
}
