@use '../../../../styles/variables' as *;

.skills__preview {
  display: flex;
  align-items: center;
  gap: 20px;
  height: clamp(100px, $height-with-navbar, 1400px);
  width: 100%;

  @include mobile-portrait {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  @include mobile-landscape {
    height: 100%;
  }

  &__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    height: 100%;
    width: 100%;

    @include mobile-portrait {
      order: 1;
    }

    @include mobile-landscape {
      height: $height-with-navbar;
    }

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      font-size: 48px;
          -webkit-text-stroke: 1px #ece6d7;
      -webkit-text-fill-color: transparent;
      writing-mode: vertical-rl;
      transform: rotate(180deg);

      &_frontend {
        border-left: 3px dashed #ece6d7;
      }

      &_backend {
        border-right: 3px dashed #ece6d7;
      }
    }

    &-content {
      border-radius: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;

      &_frontend {
        justify-content: flex-start;
        background: url("https://res.cloudinary.com/dimfha9hq/image/upload/v1695139338/portfolio_react/avatar-left.png")
            no-repeat right center / contain,
            linear-gradient(to left, rgb(223, 176, 166), rgba(0, 0, 0, 0));

        @include mobile-portrait {
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        @include mobile-landscape {
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
        }
      }

      &_backend {
        justify-content: flex-end;
        background: url("https://res.cloudinary.com/dimfha9hq/image/upload/v1695139338/portfolio_react/avatar-right.png")
            no-repeat left center / contain,
            linear-gradient(to right, rgb(117, 117, 117), rgba(0, 0, 0, 0));

        @include mobile-portrait {
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
        }

        @include mobile-landscape {
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100px;
    height: 100px;
    margin-right: 10px;

    &-icon {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      padding: 10px;

      @include mobile-portrait {
        width: 40px;
        height: 40px;
        padding: 5px;
      }

      @include mobile-landscape {
        width: 60px;
        height: 60px;
        padding: 10px;
      }
    }

    &-text {
      text-transform: uppercase;
      font-size: 14px;

      @include mobile-portrait {
        font-size: 12px;
      }

      @include mobile-landscape {
        font-size: 14px;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @include mobile-portrait {
      order: 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: auto;
    }

    &-letter {
      display: block;
      margin-block: -5px;
      font-size: 48px;
          -webkit-text-stroke: 1px #ece6d7;
      -webkit-text-fill-color: transparent;
    }
  }

  #react {
    border: 2px solid #61dbfb;
  }
  #javascript {
    border: 2px solid #fed602;
  }
  #typescript {
    border: 2px solid #3178c6;
  }
  #rails {
    border: 2px solid #ff0000;
  }
  #ruby {
    border: 2px solid #e53834;
  }
  #nestjs {
    border: 2px solid #f50157;
  }
}
