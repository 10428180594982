@use '../../../../styles/variables' as *;

.links-container {
  width: 100%;
  height: clamp(100px, $height-with-navbar - 50vh, 1400px);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  padding-inline: 72px;

  @include mobile-portrait {
    padding-inline: 20px;
    gap: 20px;
}
}


.avatar-video {
  width: 200px;
  height: 200px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;

  @include mobile-portrait {
    width: 150px;
    height: 150px;
  }
}

.links-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    width: 20%;
    @include mobile-portrait {
        width: 100%;
        gap: 10px;
    }

    p {
        background-color: $bg-section-dark;
        padding: 10px 20px;
        border-radius: 10px;
        font-weight: 500;
    }

    .link {
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: flex-end;
        background-color: #ece6d7;
        color : #000;
        padding: 10px 20px;
        border-radius: 10px;
        font-weight: 600;
        gap: 10px;
        transition: all 0.3s ease-in-out;
        &:hover {
            background-color: #000;
            color: #ece6d7;
        }
    }
}
