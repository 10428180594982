@use "../../styles/variables" as *;

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-inline: 72px;
    padding-top: 50px;
    min-height: clamp(100px, $height-with-navbar, 1400px);
    gap: 40px;
    width: 100%;

    @include mobile-portrait {
        padding-inline: 20px;
    }

    .skills__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
    }
}
