@use "../../styles/variables" as *;

.footer {
  background-color: $footer-bg-color;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  padding: 20px;
  width: 100%;

  @include mobile-portrait {
    flex-direction: column;
    gap: 40px;
  }

  @include mobile-landscape {
    flex-direction: row;
  }
}

.footer__logo {
  width: 60px;
  height: 60px;
  align-self: center;

  @include mobile-portrait {
    align-self: flex-start;
  }

  @include mobile-landscape {
    align-self: center;
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.footer__copyright {
  align-self: flex-end;
  display: flex;
  gap: 10px;
  align-items: center;

  span {
    text-align: end;
    font-size: 0.875rem;
    font-weight: 300;
    @include mobile-portrait {
        font-size: 0.75rem;
        text-align: start;
    }
  }
}
