// Hateur avec la navbar :
$height-with-navbar: calc(100vh - 50px);

// Debug :
$debug: 2px solid red;

// Footer :
$footer-bg-color: #282c34;

//==================================================//

// Banner
$banner-bg: linear-gradient(to left, #363636, #000);

// Variables pour la page ProjectDetail :
$main-font: "Poppins", sans-serif;
$main-color: #ece6d7;
$bg-section-dark: rgba(57, 57, 57, 0.5);
$bg-section-light: rgba(91, 91, 91, 0.5);


//==================================================//


@mixin container-styles {
  background-color: $bg-section-light;
  padding: 20px;
  border-radius: 20px;
}

@mixin section-styles {
  background-color: $bg-section-dark;
  padding: 20px;
  border-radius: 20px;
}


// Responsive breakpoints
@mixin mobile-portrait {
  @media (max-width: 968px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (max-width: 968px) and (orientation: landscape) {
    @content;
  }
}
