@use '../../../../styles/variables.scss' as *;


//> Composant : [ Liens du projet ]
.project-links {
  display: flex;
  border-radius: 20px;
  gap: 40px;
  @include mobile-portrait {
    flex-direction: column;
    gap: 20px;
  }

  // Lien du projet
  .project-link {
    align-items: center;
    display: flex;
    @include container-styles;

    .project-link-arrow {
      margin-left: 20px;
    }

    // Icone du lien
    .project-link-icon {
      width: 32px;
      height: 32px;
    }

    // Texte du lien
    .project-link-text {
        margin-left: 20px;
        font-size: 16px;
        text-align: justify;
        line-height: 1.75rem;
        font-weight: 200;
        text-wrap: nowrap;
    }
  }
}
