@use '../../../styles/variables' as *;
.button {
    padding: 20px;
    border: none;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, width 0.5s ease-in-out;
    text-wrap: nowrap;
  }

  .default {
    background: transparent;
    color: #ece6d7;
    border-radius: 50px;
    border: 1px solid #ece6d772;

    &:hover {
        background: #ece6d7;
        color: #17181f;
      }
  }

  .success {
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: default;
    width: 50%;
    animation: bounce 2s, glow 2s infinite;
  }

  .loading {
    background-color: #ece6d769;
    color: white;
    border: none;
    cursor: not-allowed;
    width: 80%;
    animation: breathe 2s infinite;


  }



  @keyframes breathe {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
  }


  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-15px);
    }
  }

//   @keyframes glow {
//     0% {
//       box-shadow: 0 0 5px #4caf4f5c;
//     }
//     50% {
//       box-shadow: 0 0 10px #4caf4f5c, 0 0 20px #4caf4f5c;
//     }
//     100% {
//       box-shadow: 0 0 5px #4caf4f5c;
//     }
//   }
