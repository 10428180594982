@use '../../styles/variables' as *;


.navbar {
  height: 50px;
  width: 100%;
  backdrop-filter: blur(20px);
  background-color: rgba(24, 24, 31, 0.5);
  position: fixed;
  z-index: 1000;
  top: 0;
}

.navbar__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-inline: 72px;
}

.navbar__logo {
  width: 32px;
  height: 32px;
  display: block;
}

.navbar__logo__image {
  width: 100%;
  height: 100%;
}

.navbar__hamburger {
  display: none;
}

.navbar__elements__links {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.navbar__elements__link {
  font-weight: 500;
  font-size: 1rem;
  border-radius: 10px;
  padding: 8px 16px;
  transition: all .10s ease-in-out;

  &:hover {
    background-color: #ece6d71c;
  }
}

@include mobile-portrait {

  .navbar__container {
    padding-inline: 24px;
  }

  .navbar__hamburger {
    display: block;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }

  .navbar__hamburger__image {
    width: 100%;
    height: 100%;
  }

  .navbar__elements {
    position: absolute;
    right: 0;
    top: 50px;
    background-color: rgba(24, 24, 31, 0.9);
    width: 0;
    height: $height-with-navbar;
    transition: width 0.3s ease-in-out;
  }

  .navbar__elements.active {
    width: 270px;
  }

  .navbar__elements__links {
    flex-direction: column;
    padding: 24px;
  }

  .navbar__elements__link {
    font-size: 20px;
  }
}

#contact {
    background-color: #ece6d7;
    color: #000;
    &:hover {
      color: #ece6d7;
      background-color: #000;
    }

}
