* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  line-height: 1.5;
}

html {
  font-size: 100%;

  text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;

  scroll-behavior: smooth;
}

p, span{
  overflow-wrap: break-word;
  hyphens: auto;

}

body {
  font-family: $main-font;
  color: $main-color;
  -webkit-font-smoothing: antialiased;
  background-color: #17181f;
  // background-image: url('../assets/icons/pattern.svg');
  // background-image: radial-gradient(#ece6d6 0.5px, transparent 0.5px);
  // background-size: 32px 32px;
}

#root, .App {
  min-height: 100vh;
  max-width: 100vw;
}

ol, ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  cursor: pointer;
}
