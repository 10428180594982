@use '../../styles/variables' as *;

.skills__section {
    @include section-styles;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    &-title {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        -webkit-text-stroke: 1px $main-color;
        -webkit-text-fill-color: $main-color;
        font-weight: 800;
        @include container-styles;
    }

    &-content {
        display: flex;
        flex-direction: column;
        gap: 40px;
    }
}

.skills__section-box {
    @include container-styles;
    width: 100%;
    display: flex;
    gap: 40px;

    @include mobile-portrait {
        flex-direction: column;
        gap: 20px;
    }

    &-title {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-size: clamp(1.125rem, 2vw, 1.25rem);
        text-transform: uppercase;
        letter-spacing: 0.2em;
        -webkit-text-stroke: 1px #ece6d7;
        -webkit-text-fill-color: #ece6d7;
        font-weight: 700;
        text-wrap: wrap;
        border-right: 2px solid #ece6d7;
        padding: 10px 20px;
        min-width: 30%;

        @include mobile-portrait {
            border-right: none;
        }
    }
}
