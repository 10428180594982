@use '../../styles/variables' as *;

.error-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: $height-with-navbar;
  width: 100vw;
  gap: 40px;

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;

    &__title {
      font-size: 10rem;
          -webkit-text-stroke: 1px $main-color;
      -webkit-text-fill-color: transparent;
      max-height: 100px;
      display: flex;
      align-items: center;

      @include mobile-portrait {
        font-size: 6rem;
      }

      @include mobile-landscape {
        font-size: 6rem;
      }
    }

    &__subtitle {
      font-size: 1.25rem;
      text-align: center;
    }
  }
  &__section {
    display: flex;

    &__link {
      font-size: 1.25rem;
      border: 1px solid $main-color;
      border-radius: 50px;
      padding: 10px 20px;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: $main-color;
        color: #17181f;
      }
    }
  }
}
