@use "../../styles/variables" as *;

.skills__item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // max-width: 70%;
    width: 100%;
    transition: all 0.3s ease-in-out;
    background-color: rgb(40,41,44);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    border: 1px solid rgba(143, 142, 142, 0.5);
    padding: 20px;

    &:hover {
        transform: scale(1.01);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
        border: 1px solid rgba(143, 142, 142, 0.8);
    }


    @include mobile-portrait {
        max-width: 100%;
    }

    &-title {
        display: flex;
        align-items: center;
        padding: 10px 20px;
        gap: 20px;
        text-wrap: nowrap;


        &-icon {
            width: 30px;
            height: 30px;
        }

        &-text {
            font-size: clamp(1.05rem, 2vw, 1.25rem);
            text-transform: uppercase;
            letter-spacing: 0.2em;
            -webkit-text-stroke: 1px #dad8d5;
            -webkit-text-fill-color: #dad8d5;
            font-weight: 600;
        }
    }

    &-description {
        font-size: 1rem;
        text-align: justify;
        line-height: 1.75rem;
        font-weight: 200;
        padding-inline: 20px;
    }
}
