@use '../../../../styles/variables' as *;


//> Component : [ Description du projet ]
.project-description {
  @include section-styles;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;


  @include mobile-portrait {
    gap: 40px;
  }

  .project-description__block {
    display: flex;
    gap: 40px;

    @include mobile-portrait {
      flex-direction: column;
    }

    // Container
    .project-description__content {
      display: flex;
      flex-direction: column;
      gap: 20px;


      // Titre du container
      .project-description__title {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        -webkit-text-stroke: 1px $main-color;
        -webkit-text-fill-color: $main-color;
        font-weight: 800;
        @include container-styles;
      }

      // Description du projet
      .project-description__text {
        font-size: 16px;
        text-align: justify;
        line-height: 1.75rem;
        font-weight: 200;
        @include container-styles;
      }
    }
  }
}
