@use "../../../../styles/variables.scss" as *;

//> Component : [ Stacks du projet ]
.project-stack {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    // Titre du container des stacks
    .project-stack__title {
        font-size: 24px;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        -webkit-text-stroke: 1px $main-color;
        -webkit-text-fill-color: $main-color;
        font-weight: 800;
        @include container-styles;
    }

    // Container de la liste des stacks
    .project-stack__list {
        @include container-styles;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        // Item de la liste des stacks
        .project-stack__item {
            font-size: 1rem;
            text-align: justify;
            line-height: 1.75rem;
            font-weight: 400;
            display: flex;
            justify-content: space-between;
            align-items: center;

            // Icone de chaque stack
            .project-stack__icon {
                width: 32px;
                height: 32px;
            }
        }
    }
}
