@use '../../styles/variables' as *;

.card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 20px;
  background-size: cover;
  background-position: center;

  @include mobile-portrait {
    height: 100%;
    width: 100%;
  }

  @include mobile-landscape {
    width: 100%;
  }
}

.card-content {
  background-color: rgba(0, 0, 0, 0.8);
  padding-inline: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20%;
  border-radius:0 0 20px 20px;
}

.card-title {
  font-size: 1.25rem;
  text-transform: uppercase;
}

.card-icon {
  border-radius: 50%;
  object-fit: cover;
  background-color: $main-color;
  padding: 5px;
  width: 50px;
  height: 50px;

  @include mobile-portrait {
    width: 40px;
    height: 40px;
  }
}
