@use '../../../styles/variables' as *;

.contact__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  width: 100%;
  @include container-styles;


  &__wrapper {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    width: 100%;

    @include mobile-portrait {
      flex-direction: column;
    }

    @include mobile-landscape {
      flex-direction: row;
    }
  }
}


.error__message {
  display: flex;
  align-items: center;
  gap: 5px;
  color: rgb(255, 0, 0);
  font-size: 0.8rem;
  margin-top: 5px;
  margin-left: 5px;
}
