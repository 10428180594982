@use "../../../../styles/variables" as *;

//> Composant : [ ProjectYoutube ]
.project-youtube {
    // Container de la vidéo
    @include section-styles;
    width: 100%;
    display: flex;
    flex-direction: column;

    .project-youtube__content {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;

        // Titre du container
        .project-youtube__title {
            font-size: 24px;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            -webkit-text-stroke: 1px $main-color;
            -webkit-text-fill-color: $main-color;
            font-weight: 800;
            @include container-styles;
        }

        .project-youtube__subcontent {
            display: flex;
            gap: 40px;

            @include mobile-portrait {
                flex-direction: column;
                gap: 20px;
            }

            // Vidéo Youtube
            .project-youtube__video {
                width: 50%;
                border-radius: 20px;
                border: none;
                aspect-ratio: 16/9;

                @include mobile-portrait {
                    width: 100%;
                }
            }

            .project-youtube__description {
                display: flex;
                flex-direction: column;
                width: 50%;
                gap: 20px;

                @include mobile-portrait {
                    width: 100%;
                    gap: 40px;
                }

                .project-youtube__text {
                    @include container-styles;
                    font-size: 16px;
                    text-align: justify;
                    line-height: 1.75rem;
                    font-weight: 200;

                    @include mobile-landscape {
                        font-size: 0.8rem;
                    }
                }

                .project-youtube__avatars {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    @include container-styles;

                    @include mobile-portrait {
                        flex-direction: column;
                        gap: 20px;
                    }

                    .project-youtube__avatar-title {
                        font-size: 18px;
                        text-align: center;
                        text-transform: uppercase;
                        letter-spacing: 0.2em;
                        -webkit-text-stroke: 1px $main-color;
                        -webkit-text-fill-color: $main-color;
                        font-weight: 600;
                    }
                    .project-youtube__avatar-images {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        .project-youtube__image {
                            width: 90px;
                            height: 90px;
                            object-fit: cover;
                            border-radius: 50%;
                            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75)
                                inset;
                        }
                    }
                }
            }
        }
    }
}
