@use '../../styles/variables' as *;

//=> Container de la page [ ProjectDetail ]
.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding-inline: 72px;
  padding-top: 50px;


  @include mobile-portrait {
    padding-inline: 20px;
  }
}
