@use 'swiper/css/bundle';
@use '../../styles/variables' as *;

.carousel {
  width: 100%;
  height: 100%;
  border-radius: 20px;

  .carousel__slides {
    border-radius: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    height: 100%;

  }
}

.swiper-slide-next, .swiper-slide-prev {
  filter: grayscale(1) blur(5px);
  transition: all 0.5s ease-in-out !important;
}
