@use "../../../../styles/variables" as *;

.project-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: clamp(100px, $height-with-navbar, 1400px);
    width: 100%;

    @include mobile-portrait {
        flex-direction: column;
        justify-content: center;
        gap: 50px;
    }

    @include mobile-landscape {
        flex-direction: row;
        justify-content: center;
        gap: 0px;
    }

    .title-left,
    .title-right {
        display: flex;
        justify-content: center;
        align-items: center;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        height: 50%;
        text-wrap: nowrap;

        @include mobile-portrait {
            writing-mode: horizontal-tb;
            transform: rotate(0deg);
            height: 10%;
            margin: 0;
        }

        @include mobile-landscape {
            writing-mode: vertical-rl;
            transform: rotate(180deg);
            height: 100%;
            margin: 0;
        }

        > h2 {
            text-transform: uppercase;
            font-size: 48px;
            font-family: $main-font;
            -webkit-text-stroke: 1px #ece6d7;
            -webkit-text-fill-color: transparent;
            text-wrap: nowrap;
        }
    }

    .title-left {
        border-left: 3px dashed #ece6d7;

        @include mobile-portrait {
            border-left: none;
        }
    }

    .title-right {
        border-right: 3px dashed #ece6d7;

        @include mobile-portrait {
            border-right: none;
        }
    }

    .carousel-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        overflow: hidden;
        gap: 20px;
        height: 70%;
        padding-top: 60px;

        @include mobile-portrait {
            width: 100%;
            height: 50%;
            padding: 0;
        }

        @include mobile-landscape {
            width: 100%;
            height: 100%;
            padding-top: 60px;
        }
    }
}
